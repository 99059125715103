import react, { useEffect, useState } from 'react'
import CryptoJS from 'crypto-js';

import { useHistory } from 'react-router-dom'

export default ({ h }) => {
    const history = useHistory();
    const [lastBlocks, setLastBlocks] = useState();
    const [hashedValue, setHashedValue] = useState('');

    useEffect(()=>{
        gerarDados();
        handleHash(h.cdOrder);
    },[])

    const gerarDados = async () => {
        const numBlock = Math.floor(Math.random() * 500)
        setLastBlocks(numBlock + 5)
    }

    const handleHash = (idTransaction) => {
        // Calcular o hash SHA-256
        const hash = CryptoJS.SHA256(idTransaction).toString(CryptoJS.enc.Hex);
        setHashedValue(hash);
    };

    return (
        <div className="row d-flex align-items-center blocos">
            <div className="col-1">
                <i className="fa-regular fa-file-lines topo-icons"></i>
            </div>
            <div className="col-11" >
                <div className="row">
                    <div className="col-3" style={{padding: 9}}>
                        <div className="col-12 customLinks" onClick={_ => {history.push({
                                    pathname: "/transacao/" + hashedValue,
                                    data: {
                                        transaction: h,
                                        block: lastBlocks
                                    }
                                })
                            }}>{hashedValue.substring(0, 10)}...</div>
                        <div className="col-12" style={{fontSize: 12}}>
                            {h.seconds} secs ago
                        </div>
                    </div>
                    <div className="col-8">
                        <div className="col-12">From: {h.hashSender.substring(0, 20)}</div>
                        <div className="col-12">To: {h.hashReceiver.substring(0, 20)}</div>
                    </div>
                </div>
            </div>
        </div>
    )

}