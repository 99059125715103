export default _ => {
    return (
        <footer className="container d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
            <div className="container" >
                <div className="col-sm-3" style={{marginBottom: 200, color: 'white', marginTop: 30}}>

                    <p>CipayScan is a Block Explorer, Analytics and smart contracts platform</p>
                </div>
                
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="col-md-4 d-flex align-items-center">
                            <span className="mb-3 mb-md-0 text-muted">© 2023 Company, Inc</span>
                        </div>
                        </div>
                        <div className="col-sm-6">
                            <ul className="nav justify-content-end list-unstyled d-flex">
                            <li className="ms-3"><a className="text-muted" href="#">twitter</a></li>
                            <li className="ms-3"><a className="text-muted" href="#">instragram</a></li>
                            <li className="ms-3"><a className="text-muted" href="#">facebook</a></li>
                            </ul>
                        </div>
                    </div>
                 


                </div>
            </div>
        </footer>

    )
}