import react, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getContractSign, getTokenContractSign } from '../../services/index.js';
import CircularProgress from '@mui/material/CircularProgress';
import Linha from "./components/linha.js";

export default ({ isToken, match }) => {
    const history = useHistory();
    const [historico, setHistorico] = useState([])
    const [noTransaction, setNoTransaction] = useState(false)
    const [contractSign, setContractSign] = useState({
        asset: {},
        assetBalance: {},
        contractAssets: {},
        fee: {},
        feeNetworking: {},
        saque: []
        
    });
    

    useEffect(_ => {

        const ids = isTokenID(match.params.id);
        if(ids === "contract"){
            getContractSignExplorer(match.params.id);
            console.log(contractSign, "contractSign per contract");
            console.log(historico, "contractSign transaction per contract");
            
        }else{
            const cdAsset = atob(match.params.idAsset);
            getContractSignExplorerToken(cdAsset);
            console.log(contractSign, "contractSign per token");
            console.log(historico, "contractSign transaction per token");
        }

    }, [])

    const isTokenID = (id) => {
        if(id.includes("contractCiPay")){
            return "contract"
        }

        return "token"
    }

    const getContractSignExplorer = async (sign) => {
        const contractSignResult = await getContractSign(sign)
        const result = JSON.parse(contractSignResult.data.content);
        const transaction = [];
        setContractSign({
            asset: result.content.asset,
            assetBalance: result.content.assetBalance,
            contractAssets: result.content.contractAssets,
            fee: result.content.fee,
            feeNetworking: result.content.feeNetworking,
        });

        for (let index = 0; index < result.saque.length; index++) {
            transaction.push(<Linha key={index} h={result.saque[index]} isToken={isToken} />)
        }

        if(transaction.length === 0){
            setNoTransaction(true);
        }else{
            setNoTransaction(false);
        }

        setHistorico(transaction)
 
    }

    const getContractSignExplorerToken = async (sign) => {
        const contractSignResult = await getTokenContractSign(sign)
        const result = JSON.parse(contractSignResult.data.content);
        const transaction = [];
        setContractSign({
            asset: result.content.asset,
            assetBalance: result.content.assetBalance,
            contractAssets: result.content.contractAssets,
            fee: result.content.fee,
            feeNetworking: result.content.feeNetworking,
        });

        for (let index = 0; index < result.saque.length; index++) {
            transaction.push(<Linha key={index} h={result.saque[index]} isToken={isToken} />)
        }

        if(transaction.length === 0){
            setNoTransaction(true);
        }else{
            setNoTransaction(false);
        }

        setHistorico(transaction)
 
    }

    return <>
        <div className="container">

            <div className="row">
                <div className="col-auto">
                    <img style={{ borderRadius: 15, width: 50 }} src={contractSign?.asset?.urlAsset} />
                </div>
                {
                    isToken ?
                        <div className="col-sm-8 mt-2" style={{color: 'white'}}><h4>Token {contractSign?.asset?.nmAsset}</h4> </div>
                        :
                        <div className="col-sm-9 mt-2"><h5 style={{color: 'white'}}>Address {contractSign?.contractAssets?.adressContract}</h5></div>
                }
            </div>
            <div className="row mt-3">
                <div className="col-sm-4">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Overview</h5>
                            <div className="row">
                                {
                                    isToken ?
                                        <>
                                            <div className="col-sm-12" style={{fontSize: 15}}>
                                                MAX TOTAL SUPPLY
                                            </div>
                                            <div className="col-sm-12" style={{fontSize: 14}}>
                                                {contractSign?.assetBalance.balance} <i className="fa-solid fa-circle-info"></i>
                                            </div>
                                            <div className="col-sm-12" style={{fontSize: 15}}>
                                                TOTAL TRANSFERS
                                            </div>
                                            <div className="col-sm-12" style={{fontSize: 14}}>
                                                {historico?.length} <i className="fa-solid fa-circle-info"></i>
                                            </div>
                                        </>
                                        : 
                                        <div className="col-sm-12" style={{fontSize: 14}}>
                                            BALANCE {contractSign?.assetBalance.balance}
                                        </div>
                                }
                            </div>
                            <div className="row">

                            </div>
                        </div>
                    </div>
                </div>
                {
                    isToken &&
                    <div className="col-sm-8">
                        <div className="card" style={{paddingBottom: 42}}>
                            <div className="card-body">
                                <h5 className="card-title">Other Info</h5>
                                <div className="row">
                                    <div className="col-12" style={{fontSize: 14}}>
                                        TOKEN CONTRACT
                                    </div>
                                    <div className="col-12">
                                        <i className="fa-regular fa-file-lines"></i> 
                                        <span style={{margin:5}} className="customLinks" onClick={_ => {
                                            history.push({
                                                pathname: "/contrato/" + contractSign?.contractAssets?.adressContract,
                                            })
                                        }}>
                                            {contractSign?.contractAssets?.adressContract} 
                                        </span> 
                                        <i className="fa-regular fa-clipboard"></i>
                                    </div>
                                </div>
                                <div className="row">

                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
            <div className="row mt-3">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-body">
                            <table className="table">
                            
                                <thead>
                                    <tr>
                                        <th scope="col"><i className="fa-regular fa-circle-question"></i></th>
                                        <th scope="col">Transaction Hash</th>
                                        {
                                            !isToken && 
                                                <th scope="col">Block</th>
                                        }
                                        <th scope="col">From</th>
                                        <th scope="col">To</th>
                                        <th scope="col">Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                    {
                                        noTransaction && 
                                        <>
                                            <td colSpan="6" className="text-center">No Transactions</td>
                    
                                        </>
                                    }

                                    {
                                        historico.length === 0 && noTransaction === false? 
                                        <tr>
                                            <td colSpan="6" className="text-center">
                                                <div style={{ display: 'flex', 
                                                                justifyContent: 'center', 
                                                                alignItems: 'center', 
                                                                height: '100px' 
                                                            }}>
                                                    <CircularProgress size={55} />
                                                </div>
                                            </td>
                                        </tr> : historico 
                                    }

                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}