import { useHistory } from 'react-router-dom'
import React, { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';

export default ({ isToken, h }) => {
    const history = useHistory();
    const [hashedValue, setHashedValue] = useState('');
    const [lastBlocks, setLastBlocks] = useState()

    useEffect(() => {
        handleHash(h.cdOrder);
        gerarDados();
    }, [])

    const handleHash = (idTransaction) => {
        // Calcular o hash SHA-256
        const hash = CryptoJS.SHA256(idTransaction).toString(CryptoJS.enc.Hex);
        setHashedValue(hash);
    };

    const gerarDados = async () => {
        const numBlock = Math.floor(Math.random() * 500)
        let seconds = Math.floor(Math.random() * 20)
        setLastBlocks(numBlock + 5)
    }

    const formatHash = (hash) => {
        if (hash) {
            const firstPart = hash.substring(0, 4);
            const secondPart = hash.substring(hash.length - 4);
            return (
                <>
                    {firstPart}-{secondPart}
                </>
            );
        }
        else {
            return (
                <>
                    {'-'}
                </>
            );
        }
    };

    return (
        <tr>
            <td><i className="fa-regular fa-eye"></i></td>
            <td className='customLinks' onClick={_ => {
                history.push({
                    pathname: "/transacao/" + hashedValue,
                    data: {
                        transaction: h,
                        block: lastBlocks
                    }
                })
            }}>{hashedValue.substring(0, 15)}...</td>

            {!isToken &&
                <td>{lastBlocks}</td>
            }
            <td>{formatHash(h.hashSender)}</td>
            <td>{formatHash(h.hashReceiver)}</td>
            <td>{h.cointQuantity}</td>
        </tr>
    )
}