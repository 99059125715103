import react, { useEffect, useState } from 'react'
import { LineChart, Line, XAxis, Tooltip } from 'recharts';
import AutoComplete from './components/AutoComplete';
import CircularProgress from '@mui/material/CircularProgress';

import { getHistorico } from '../../services';
import Blocks from './components/blocks'
import Topo_quadro from './components/topo_quadro'
import Transactions from './components/transactions'
import './style.css'

const recipient = ['rsync-builder', 'Flashbots: Builder', 'builder0x69', 'Fee Recipient: 0xe68...']

export default _ => {
    const [blocks, setBlocks] = useState([])
    const [transactions, setTransactions] = useState([])
    const [lastBlocks, setLastBlocks] = useState()

    useEffect(_ => {
        gerarDados()
        getTransactions()
    }, [])

    const gerarDados = async () => {
        const numBlock = Math.floor(Math.random() * 500)
        let seconds = Math.floor(Math.random() * 20)
        setLastBlocks(numBlock + 5)
        const tmp = []

        for (let index = numBlock; index < numBlock + 6; index++) {

            const tmpRecipient = recipient[Math.floor(Math.random() * recipient.length)]
            tmp.push(<Blocks numBlock={index} seconds={seconds} recipient={tmpRecipient} />)

            seconds += Math.floor(Math.random() * 60)
        }

        setBlocks(tmp)
    }

    const getTransactions = async _ => {
        const hist = await getHistorico()
        const tmpTrans = []

        for (let index = 0; index < hist.length; index++) {
            const h = hist[index]
            tmpTrans.push(<Transactions h={h} />)
        }
        console.log(tmpTrans, 'tmpTrans')
        setTransactions(tmpTrans)
    }

    return <>
        <div className="container-fluid" style={{backgroundColor: '#D4AA51'}}>
            <div className="card-body container">
                <div className="row">
                    <div className="col-sm-6" style={{paddingLeft: 35,marginBottom: 150, marginTop: 80}}>
                        <AutoComplete/>    
                    </div>
                </div>
            </div>
        </div>

        <div className='container'>

            <div className="card" style={{marginTop: 10}}>
                <div className="card-body" >
                    <div className="row">
                        <div className="col-sm-9" >
                            <div className="row">
                                <div className="col-sm-6  topo-q-linha pb-2" >
                                    <Topo_quadro icon={"fa-solid fa-server"} titulo={"TRANSACTIONS"} detalhe={"1.957 Mil"} />
                                </div>
                                <div className="col-sm-6 topo-q-coluna topo-q-linha pb-2">
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6 mt-2" >
                                    <Topo_quadro icon={"fa-solid fa-globe"} titulo={"MARKET CAP"} detalhe={"$~~"} />
                                </div>
                                <div className="col-sm-6 topo-q-coluna mt-2" >
                                    <Topo_quadro icon={"fa-solid fa-gauge"} titulo={"Último Bloco"} detalhe={lastBlocks} />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3 topo-q-coluna" >
                            Histórico de transações
                            <div className="row">
                                <LineChart width={300} height={90}
                                    data={[
                                        { name: 'Jan 22', uv: 400 },
                                        { name: 'Fev 22', uv: 200 },
                                        { name: 'Mar 22', uv: 300 },
                                        { name: 'Abr 22', uv: 100 },
                                        { name: 'Mai 22', uv: 300 },
                                        { name: 'Jun 22', uv: 400 },
                                    ]}>
                                    <Line type="monotone" dataKey="uv" stroke="#8884d8" />
                                    <XAxis dataKey="name" />
                                    <Tooltip />
                                </LineChart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-sm-6" >
                    <div className="card" >
                        <div className="card-header" style={{backgroundColor:  'rgb(25, 25, 25)', color: 'white'}}>
                            Últimos Blocos
                        </div>
                        <div className="card-body" >
                            {blocks}
                        </div>
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="card" >
                        <div className="card-header" style={{backgroundColor:  'rgb(25, 25, 25)', color: 'white'}}>
                            Últimas transações
                        </div>
                        <div className="card-body">
                            {transactions.length == 0 ? <div className='container col-1'>
                                <CircularProgress />
                            </div> : transactions}
                            
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </>
}