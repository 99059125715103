import React from 'react';
import { HashRouter, Switch } from 'react-router-dom';
import './App.css';
import Footer from './layout/footer';
import Header from './layout/header';
import AppRouter from './routes/AppRouter';
import AutoComplete from './pages/home/components/AutoComplete'

export default () => {

    return <>
        <div className='container'>
        </div>

        <div  style={{marginTop: 10}}>


                <Header/>

            <HashRouter>
                <Switch>
                    <AppRouter />
                </Switch>
            </HashRouter>
            <Footer/>
        </div>
    </>
}