export default ({icon,titulo,detalhe}) => {

    return (
        <div className="row d-flex align-items-center">
            <div className="col-1">
                <i className={icon+" topo-icons"}></i>
            </div>
            <div className="col-9" >
                <div className="col-12">
                    {titulo}
                </div>
                <div className="col-12">
                    {detalhe}
                </div>
            </div>
        </div>
    )

}