export default ({numBlock,seconds,recipient})=> {

    return (
        <div className="row d-flex align-items-center blocos" >
            <div className="col-sm-1">
                <i className="fa-solid fa-cube"></i>
            </div>
            <div className="col-sm-11" style={{padding:4}}>
                <div className="row">
                    <div className="col-sm-3">
                        <div className="col-sm-12">{numBlock}</div>
                        <div className="col-sm-12" style={{fontSize: 12}}>{seconds} secs ago</div>
                    </div>
                    <div className="col-sm-8">
                        <div className="col-sm-12">Fee Recipient {recipient}</div>
                    </div>
                    {/* <div className="col-2">
                        <div className="col-12">17197707</div>
                        <div className="col-12">13 secs ago</div>
                    </div> */}
                </div>
            </div>
        </div>
    )

}