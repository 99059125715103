import axios from "axios";

const urlAPI = "https://apigatewaycipay.azure-api.net/explorer/PostApi";
//const urlAPI = "https://localhost:44310/PostApi";

export const gerarHash = (val) => {
    return crypto.subtle
        .digest('SHA-256', new TextEncoder('utf-8').encode(val))
        .then(h => {
            let hexes = [],
                view = new DataView(h);
            for (let i = 0; i < view.byteLength; i += 4)
                hexes.push(('00000000' + view.getUint32(i).toString(16)).slice(-8));
            return hexes.join('');
        });
}

export const getContractSign = async (adressContract) => {
    try {
        const respToken = await axios.post(urlAPI, {
            command: "ContractSignOrderHistoryCommand",
            caller: "POST",
            oAuth: "string",
            content: [`{\r\n \"cdContract\": 0,\r\n \"cdAsset\": 0,\r\n \"cdAssetsBalance\": 2,\r\n \"adressContract\": \"${adressContract}\",\r\n \"ciAddress\": \"11111\",\r\n \"dtInsercao\": \"0001-01-01T00:00:00Z\"\r\n}`]
        });

        return respToken;
    } catch (error) {

    }

}

export const getTokenContractSign = async (cdAsset) => {
    try {
        const respToken = await axios.post(urlAPI, {
            command: "ContractAssetOrderHistoryCommand",
            caller: "POST",
            oAuth: "string",
            content: [`{\r\n  \"cdContract\": 0,\r\n  \"cdAsset\": ${cdAsset},\r\n  \"cdAssetsBalance\": 0,\r\n  \"adressContract\": \"string\",\r\n  \"ciAddress\": \"string\",\r\n  \"dtInsercao\": \"2023-11-15T20:07:41.098Z\"\r\n}`]
        });

        return respToken;
    } catch (error) {

    }

}

export const getNameAsset = async (nmAsset) => {
    return axios({
        method: "post",
        url: "https://smart-contract-networking.herokuapp.com/Asset/nameAsset",
        data: {
            nmAsset
        }
    })
}

export const getSearch = async (search) => {

    try {
        const respToken = await axios.post(urlAPI, {
            command: "SearchCommand",
            caller: "POST",
            oAuth: "string",
            content: [`{\r\n  \"nmAsset\": \"${search}",\r\n  "adressContract": ""\r\n}`]
        });

        return respToken;



    } catch (error) {

    }

}

export const getContractSignPerAsset = async (cdAsset) => {
    return axios({
        method: "post",
        url: "https://smart-contract-networking.herokuapp.com/Contract/contractSignPerAsset",
        data: {
            cdAsset
        }
    })
}

export const getHistorico = async _ => {
    try {
        const respToken = await axios.post(urlAPI, {
            command: "TransactionCommand",
            caller: "GET",
            oAuth: "string",
            content: []
        })

        console.log(respToken, "getHistorico")
        const jsonTransaction = JSON.parse(respToken.data.content)

        if (respToken.status == 200) {

            const filter = jsonTransaction.saque.filter(s => s.hashSender != null && s.hashSender.length > 10)
            const histTemp = []
            let seconds = Math.floor(Math.random() * 20)

            for (let index = 0; index < filter.length; index++) {
                const h = filter[index]

                const hash = await gerarHash(JSON.stringify(h))

                const block = Math.floor(Math.random() * 500)

                seconds = seconds + 3

                histTemp.push({ ...h, block, hash, seconds })
            }

            return histTemp

        }

    } catch (error) {
        console.log(error);
        return []
    }
}

export const getWallet = async (hash) => {
    try {
        const respToken = await axios.post(urlAPI, {
            command: "WalletCommand",
            caller: "POST",
            oAuth: "string",
            content: [
                `{"hashWallet": "${hash}"}`
            ]
        });

        return respToken;

    } catch (error) {
        // Handle error
    }
}

export const getSaldoPatrimonio = async (hashWallet) => {
    try {
        const resp = await axios.post(urlAPI, {
            command: "WalletBalanceSheetCommand",
            caller: "POST",
            oAuth: "string",
            content: [
                `{"AddressWallet": "${hashWallet}"}`
            ]
        });

        return resp;

    } catch (error) {
        // Handle error
    }
}