import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { getSearch } from "../../../../services";

export default function SearchInput() {

  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState("");
  const [visible, setVisible] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);

  const handleInputChange = async (value) => {
    setSearchTerm(value);
    let options = [];
    var search = (null);

    setFilteredOptions([{ label: "Aguarde..." }]);

    if (value.length >= 1) {
      const resToken = await getSearch(value);

      if (resToken.data.content) {
        search = JSON.parse(resToken.data.content);
        if (search.length > 0 || search.content !== null) {

          if (resToken.data.typeTarget === "Contract") {
            options.push({
              isToken: false,
              value: search.content.contractAssets.cdContract,
              label: search.content.contractAssets.adressContract,
              img: search.content.asset.urlAsset,
              isWallet: false,
            });
          }
          else if (resToken.data.typeTarget === "WalletAddress") {
            options.push({
              isToken: false,
              value: 1,
              label: search.conteudo.address,
              img: "https://icons.veryicon.com/png/o/miscellaneous/bilibilibili/wallet-286.png",
              isWallet: true,
            });
          }
          else {
            search.forEach(element => {
              if (element.urlAsset.includes("https") || element.urlAsset.includes("http")) {
                options.push({
                  isToken: true,
                  value: element.cdAsset,
                  label: element.nmAsset,
                  img: element.urlAsset,
                  isWallet: false,
                });
              }
            });
          }

          setVisible(false);
          setFilteredOptions(options);
        } else {
          options = [];
          setFilteredOptions([{ label: "Pesquisa Não Encontrada !" }]);
          setVisible(true);
        }
      } else {
        setFilteredOptions([{ label: "Pesquisa Não Encontrada !" }]);
        setVisible(true);
      }
    }
  };

  const handleOptionClick = (option) => {

    if (option.label === "Pesquisa Não Encontrada !") {
      return;
    }

    setSearchTerm(option.label);
    setFilteredOptions([]);

    if (option.isToken) {
      history.push({
        pathname: "/token/" + option.label + "/" + btoa(option.value),
      });
    } else if (option.isWallet) {
      history.push({
        pathname: "/addressWallet/" + option.label,
      });
    } else {
      history.push({
        pathname: "/contrato/" + option.label,
      });
    }
  };

  return (
    <div style={{ backgroundColor: 'white', borderRadius: 5 }}>
      <Autocomplete
        FreeSolo
        options={filteredOptions}
        getOptionLabel={(option) => option.label}

        renderOption={(props, option) => (
          <li {...props} onClick={() => handleOptionClick(option)}>
            {!visible &&
              <img
                style={{ borderRadius: 15, width: 30, marginRight: 10 }}
                src={option.img}
                alt={option.label}
              />
            }
            {option.label}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Digite sua busca"
            value={searchTerm}
            onChange={(event) => handleInputChange(event.target.value)}
          />
        )}
      />
    </div>
  );
}