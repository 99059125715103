import React from "react";
import { Route } from "react-router-dom";
import Home from "../pages/home";
import Transacoes from "../pages/transacoes";
import Contratos from "../pages/contratos";
import AddressWallet from "../pages/addressWallet";

export default () => {
  return <>
    <Route exact path={`/`} render={props => <Home {...props} />} />
    <Route exact path={`/transacao/:id`} render={props => <Transacoes {...props} />} />
    <Route exact path={`/contrato/:id`} render={props => <Contratos {...props} />} />
    <Route exact path={`/token/:id/:idAsset`} render={props => <Contratos {...props} isToken={true} />} />
    <Route exact path={'/addressWallet/:address'} render={props => <AddressWallet {...props} />} />
  </>
};