import * as React from 'react';
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { RxCopy } from "react-icons/rx";
import { MdWallet } from "react-icons/md";
import { PiQrCodeLight } from "react-icons/pi";
import { FaCircleExclamation } from "react-icons/fa6";
import { useHistory } from "react-router-dom";
import Linha from '../contratos/components/linha';
import { getWallet, getSaldoPatrimonio } from '../../services/index.js';
import { useParams } from 'react-router-dom';

//material
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function CustomTabPanel(props) {
     const { children, value, index, ...other } = props;

     return (
          <div
               role="tabpanel"
               hidden={value !== index}
               id={`simple-tabpanel-${index}`}
               aria-labelledby={`simple-tab-${index}`}
               {...other}
          >
               {value === index && (
                    <Box sx={{ p: 3 }}>
                         <Typography>{children}</Typography>
                    </Box>
               )}
          </div>
     );
}

CustomTabPanel.propTypes = {
     children: PropTypes.node,
     index: PropTypes.number.isRequired,
     value: PropTypes.number.isRequired,
};

function a11yProps(index) {
     return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
     };
}

const columns = [
     { id: 'token', label: 'Token', minWidth: 80 },
     { id: 'smartcontract', label: 'Smartcontract', minWidth: 80 },
     { id: 'balance', label: 'Balance', minWidth: 80 }
];

export default _ => {
     const location = useLocation();
     const { address } = useParams();
     const history = useHistory();

     const [copied, setCopied] = useState(false);
     const [value, setValue] = useState(0);
     const [loadingCarteira, setLoadingCarteira] = useState(false);
     const [loadingSaldo, setloadingSaldo] = useState(false);
     const [loadingAddress, setLoadingAddress] = useState(false);
     const [noTransaction, setNoTransaction] = useState(false)

     const [texEndereco, setTexEndereco] = useState('');
     const [saldoPatrimonio, setSaldoPatrimonio] = useState('');
     const [totalCryptoInDollarReceived, setTotalCryptoInDollarReceived] = useState('');
     const [totalCryptoInDollarSent, setTotalCryptoInDollarSent] = useState('');
     const [historico, setHistorico] = useState([]);
     const [walletCryptos, setWalletCryptos] = useState([]);
     const [totalRecebido, setTotalRecebido] = useState(0);
     const [totalEnviado, setTotalEnviado] = useState(0);

     useEffect(() => {
          Carteira();
          SaldoPatrimonioWallet();
          if (copied) {
               const timeout = setTimeout(() => {
                    setCopied(false);
               }, 2000);
               return () => clearTimeout(timeout);
          }
     }, [copied])

     const copyToClipboard = (address) => {
          navigator.clipboard.writeText(address);
          setCopied(true);
     }

     const handleChange = (event, newValue) => {
          setValue(newValue);
     }

     const calculoBalanco = (endereco, hasSender, valor, totalSent, totalReceived) => {
          if (endereco === hasSender) {
               totalSent += valor;
          } else {
               totalReceived += valor;
          }

          return { totalSent, totalReceived };
     };

     const Carteira = async _ => {
          try {
               setTexEndereco(address);
               setLoadingAddress(true);
               const result = await getWallet(address);
               const wallet = JSON.parse(result.data.content);

               const transaction = [];
               let valueCrypto = 0;
               let totalSent = 0;
               let totalReceived = 0;

               setWalletCryptos(wallet.walletCryptos);
               setLoadingCarteira(true);

               for (let index = 0; index < wallet.transacoes.saque.length; index++) {
                    const { hashSender, cointQuantity } = wallet.transacoes.saque[index];
                    const { totalSent: updatedTotalSent, totalReceived: updatedTotalReceived } = calculoBalanco(wallet.address, hashSender, cointQuantity, totalSent, totalReceived);
                    totalSent = updatedTotalSent;
                    totalReceived = updatedTotalReceived;
                    transaction.push(<Linha key={index} h={wallet.transacoes.saque[index]} isToken={true} />);
               }

               valueCrypto = parseFloat(wallet.cotacaoCrypto.valorMercadoCrypto.replace("¤", ""));

               setTotalCryptoInDollarReceived((totalReceived * valueCrypto).toFixed(3));
               setTotalCryptoInDollarSent((totalSent * valueCrypto).toFixed(3));
               setHistorico(transaction);
               setTotalEnviado(totalSent);
               setTotalRecebido(totalReceived);

               if (transaction.length === 0) {
                    setNoTransaction(true);
               } else {
                    setNoTransaction(false);
               }

               //console.log('Carteira', wallet.transacoes.saque);
          } catch (error) {
               //console.error('Erro ao obter dados da carteira', error);
          }
     }

     const SaldoPatrimonioWallet = async _ => {
          try {
               const result = await getSaldoPatrimonio(address);
               const saldo = JSON.parse(result.data.content);
               let valor = parseFloat(saldo.valorPatrimonio);
               
               setSaldoPatrimonio("$" + valor.toFixed(3));
               setloadingSaldo(true);
          } catch (error) {
               // Lidar com o erro
          }
     }


     const formatHash = (hash) => {
          let firstPart = ""
          let secondPart = "";

          if (hash) {
               if (hash.includes("contractCiPay")) {
                    firstPart = hash.substring(0, 7);
                    secondPart = hash.substring(hash.length - 4);
               } else {
                    firstPart = hash.substring(0, 4);
                    secondPart = hash.substring(hash.length - 4);
               }
               return (
                    <>
                         {firstPart}-{secondPart}
                    </>
               );
          } else {
               return (
                    <>
                         {'-'}
                    </>
               );
          }
     };

     return (
          <>
               <div className="display-6 container" style={{ fontSize: 27, color: 'white', marginBottom: 15 }}>
                    <h2><b>Wallet</b></h2>
               </div>
               <div className="container">
                    <div className="row">
                         <div className="col">
                              <div style={{ backgroundColor: 'white', borderRadius: '10px', padding: '20px' }}>
                                   <div style={{ display: 'flex' }}>
                                        <div style={{ marginRight: '15px' }}>
                                             {/*<img
                                                  style={{ width: 150, marginBottom: 15 }}
                                                  src={"https://icons.veryicon.com/png/o/business/work-circle/cryptocurrency-wallet.png"}
                                                  alt={"wallet"}
                                             />*/}
                                             {<MdWallet style={{ fontSize: '8em' }} />}
                                        </div>
                                        <div>
                                             <div>
                                                  <h3>{loadingAddress ? formatHash(texEndereco) : <CircularProgress size={20} />}</h3>
                                             </div>
                                             <div style={{ display: 'flex' }}>
                                                  <div style={{
                                                       background: 'rgb(212, 170, 81)',
                                                       borderRadius: '10px',
                                                       height: '10%',
                                                       marginRight: '10px'
                                                  }}>
                                                       <PiQrCodeLight
                                                            style={{ fontSize: '3.1em', color: 'white', cursor: 'pointer' }}
                                                            title="Show QR Code"
                                                       />
                                                  </div>
                                                  <div>
                                                       <div>
                                                            <p style={{ marginBottom: '0px' }}>CIPay Address</p>
                                                            <div style={{ display: 'flex' }}>
                                                                 <p>{loadingAddress ? texEndereco : <CircularProgress size={20} />}</p>
                                                                 <RxCopy
                                                                      style={{
                                                                           marginLeft: '10px',
                                                                           cursor: 'pointer',
                                                                           fontSize: '1.2em',
                                                                      }}
                                                                      onClick={() => copyToClipboard(texEndereco)}
                                                                 />
                                                                 {copied && (
                                                                      <div style={{
                                                                           backgroundColor: 'rgba(224, 224, 224, 200)',
                                                                           borderRadius: '10px',
                                                                           padding: '10px',
                                                                           marginLeft: '10px',
                                                                      }}>
                                                                           copied to clipboard
                                                                      </div>
                                                                 )}
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                                   <div style={{
                                        backgroundColor: '#E0E0E0',
                                        borderRadius: '25px',
                                        padding: '15px',
                                        width: '70%'
                                   }}>
                                        <div>
                                             <div>
                                                  <h5>CIPay Balance</h5>
                                                  <p>{loadingSaldo ? saldoPatrimonio : <CircularProgress size={25} />}</p>
                                             </div>

                                        </div>
                                   </div>
                                   <div className='box-tab' style={{ marginTop: '45px', alignItems: 'center' }}>
                                        <Box sx={{ width: '100%' }}>
                                             <Box sx={{ borderBottom: 1, borderColor: 'divider' }} style={{
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                  alignItems: 'center',
                                             }}>
                                                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                                       <Tab label="Wallet" {...a11yProps(0)} />
                                                       <Tab label="Tokens" {...a11yProps(1)} />
                                                  </Tabs>
                                             </Box>
                                             <CustomTabPanel value={value} index={0}>
                                                  <div style={{ display: 'flex' }}>
                                                       <div>
                                                            <p style={{ color: 'gray', marginBottom: '0px', fontSize: 13 }}>Total Received
                                                                 <FaCircleExclamation style={{
                                                                      color: 'gray',
                                                                      marginLeft: '5px',
                                                                      fontSize: 13,
                                                                      cursor: 'pointer'
                                                                 }}
                                                                      title='Total amount received from this address over time'
                                                                 ></FaCircleExclamation>
                                                            </p>
                                                            <p style={{ marginBottom: '0px', fontSize: 13 }}><b>{totalRecebido} CIP</b></p>
                                                            <p style={{ color: 'gray', fontSize: 13 }}>${totalCryptoInDollarReceived}</p>
                                                       </div>
                                                       <div style={{ marginLeft: '150px' }}>
                                                            <p style={{ color: 'gray', marginBottom: '0px', fontSize: 13 }}>Total sent
                                                                 <FaCircleExclamation style={{
                                                                      color: 'gray',
                                                                      marginLeft: '5px',
                                                                      fontSize: 13,
                                                                      cursor: 'pointer'
                                                                 }}
                                                                      title='Total amount sent from this address over time'
                                                                 ></FaCircleExclamation>
                                                            </p>
                                                            <p style={{ marginBottom: '0px', fontSize: 13 }}><b>{totalEnviado} CIP</b></p>
                                                            <p style={{ color: 'gray', fontSize: 13 }}>${totalCryptoInDollarSent}</p>
                                                       </div>
                                                  </div>
                                                  <hr></hr>
                                                  <div style={{ marginLeft: '50px' }}>
                                                       <h5><b>Transactions</b></h5>
                                                       <div className="card-body">
                                                            <TableContainer sx={{ maxHeight: 450 }}>
                                                                 <table className="table">
                                                                      <thead>
                                                                           <tr>
                                                                                <th scope="col"><i className="fa-regular fa-circle-question"></i></th>
                                                                                <th scope="col">Transaction Hash</th>
                                                                                {
                                                                                     !true &&
                                                                                     <th scope="col">Block</th>
                                                                                }
                                                                                <th scope="col">From</th>
                                                                                <th scope="col">To</th>
                                                                                <th scope="col">Value</th>
                                                                           </tr>
                                                                      </thead>
                                                                      <tbody>
                                                                           {
                                                                                noTransaction &&
                                                                                <>
                                                                                     <td colSpan="6" className="text-center">No Transactions</td>
                                                                                </>
                                                                           }
                                                                           {
                                                                                historico.length === 0 && noTransaction === false ?
                                                                                     <tr>
                                                                                          <td colSpan="6" className="text-center">
                                                                                               <div style={{
                                                                                                    display: 'flex',
                                                                                                    justifyContent: 'center',
                                                                                                    alignItems: 'center',
                                                                                                    height: '100px'
                                                                                               }}>
                                                                                                    <CircularProgress size={55} />
                                                                                               </div>
                                                                                          </td>
                                                                                     </tr> : historico
                                                                           }
                                                                      </tbody>
                                                                 </table>
                                                            </TableContainer>
                                                       </div>
                                                  </div>
                                             </CustomTabPanel>
                                             <CustomTabPanel value={value} index={1}>
                                                  <Paper sx={{ width: '70%' }} style={{ marginTop: 30 }}>
                                                       <TableContainer sx={{ maxHeight: 450 }}>
                                                            <Table stickyHeader aria-label="sticky table">
                                                                 <TableHead>
                                                                      <TableRow>
                                                                           {columns.map((column) => (
                                                                                <TableCell className="sticky-header" key={column.id} style={{ minWidth: column.minWidth, textAlign: 'center' }}>
                                                                                     <b>{column.label}</b>
                                                                                </TableCell>
                                                                           ))}
                                                                      </TableRow>
                                                                 </TableHead>
                                                                 {
                                                                      loadingCarteira ? (
                                                                           <TableBody style={{ textAlign: 'center' }}>
                                                                                {walletCryptos
                                                                                     .map((crypto) => (
                                                                                          <TableRow hover key={crypto.cdCrypto}>
                                                                                               <TableCell align="center" style={{ verticalAlign: 'middle' }}>
                                                                                                    <img height={'20px'} width={'20px'} src={crypto.urlImgCrypto} alt={crypto.eliasCrypto} />
                                                                                                    <span style={{ marginLeft: '10px' }}>{crypto.eliasCrypto}</span>
                                                                                               </TableCell>

                                                                                               <TableCell align="center">
                                                                                                    <span style={{ margin: 5 }} className="customLinks" onClick={_ => {
                                                                                                         crypto.adressContract ? (
                                                                                                              history.push({
                                                                                                                   pathname: "/contrato/" + crypto.adressContract,
                                                                                                              })
                                                                                                         ) : (
                                                                                                              <></>
                                                                                                         )
                                                                                                    }}>
                                                                                                         {formatHash(crypto.adressContract)}
                                                                                                    </span>
                                                                                               </TableCell>
                                                                                               <TableCell align="center">
                                                                                                    {crypto.vlSaldo}
                                                                                               </TableCell>
                                                                                          </TableRow>
                                                                                     ))
                                                                                }
                                                                           </TableBody>
                                                                      ) : (
                                                                           <td colSpan="6" className="text-center">
                                                                                <div style={{
                                                                                     display: 'flex',
                                                                                     justifyContent: 'center',
                                                                                     alignItems: 'center',
                                                                                     height: '100px'
                                                                                }}>
                                                                                     <CircularProgress size={55} />
                                                                                </div>
                                                                           </td>
                                                                      )
                                                                 }
                                                            </Table>
                                                       </TableContainer>
                                                  </Paper>
                                             </CustomTabPanel>
                                        </Box>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </>
     );
}
